export default [
  {
    key: "search",
    label: "field.title",
    type: "text",
  },
  {
    key: "weight",
    label: "field.weight",
    type: "text",
  },
  {
    key: "isEnable",
    label: "field.status",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.active", value: "1" },
      { text: "field.inactive", value: "0" },
    ],
    lg: "auto",
  },
];
